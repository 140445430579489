.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.body {
  height: 100vh;
}

.layoutContent {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.content {
  margin: 24px 16px;
  background: white;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.02);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}