.uploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.06);
}

.uploadArea {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transition: all 0.5s;
    padding-left: 75%;
    padding-top: 50%;
}

.uploadArea:hover {
    color: white;
    background: rgba(0,0,0,0.6);
    padding: 0;
}

.coverContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 140px;
    border-radius: 10px;
    overflow: clip;
}
